<template>
  <div class="login">
    <!-- <h1>This is an login page</h1> -->
    <div class="topBar stickTop">
      <div class="logoIcon">
        <img src="images/logo-white.png" alt="" />
      </div>
      <div class="logoName">
        <span class="name1">Hotspex</span>
        <span class="name2">STUDIO</span>
      </div>
    </div>
    <div class="centerContent">
      <section class="formContainer">
        <div class="formTop twocol">
          <h2>Login</h2>
          <router-link :to="{ name: 'signup' }" class="linkBtn"
            >SIGN UP</router-link
          >
        </div>
        <div class="formBody">
          <p class="lg-Text">
            Please <b>login below</b> to launch your project today!
          </p>
          <p>
            No account?
            <router-link :to="{ name: 'signup' }" class="redLink"
              >Sign up now</router-link
            >
          </p>

          <form>
            <!-- email -->
            <div class="field">
              <label class="lineLable">Email</label>
              <div class="iconInput">
                <span class="icon"><font-awesome-icon icon="envelope" /></span>
                <input
                  type="email"
                  v-model="email"
                  required
                  class="fullWidth"
                />
              </div>
            </div>
            <!-- password -->
            <div class="field">
              <label class="lineLable">password</label>
              <div class="iconInput">
                <span class="icon"
                  ><font-awesome-icon icon="unlock-alt"
                /></span>
                <input
                  :type="pswdType"
                  v-model="password"
                  required
                  class="fullWidth"
                />
                <span
                  class="icon fixRight iconBtn"
                  v-show="password.length > 0 && !pswdVisible"
                  @click="togglePassword"
                  ><font-awesome-icon icon="eye"
                /></span>
                <span
                  class="icon fixRight iconBtn"
                  v-show="password.length > 0 && pswdVisible"
                  @click="togglePassword"
                  ><font-awesome-icon icon="eye-slash"
                /></span>
              </div>
            </div>
            <div class="field twocol">
              <label class="sm-text checkbox-label">
                <input type="checkbox" />
                Remember me
              </label>
              <router-link :to="{ name: 'forgot-password' }" class="sm-text"
                >Forget Password</router-link
              >
            </div>
            <div class="failBG sm-text" v-if="msg !== ''" v-html="msg"></div>
            <button
              class="button darkgreyBtn"
              @click.prevent="userLogin"
              :disabled="!formValid"
            >
              LOGIN
            </button>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
export default {
  data() {
    return {
      pswdVisible: false,
      password: "",
      email: "",
      password_valid: false,
      email_valid: false,
      msg: "",
    };
  },
  computed: {
    pswdType() {
      return this.pswdVisible ? "text" : "password";
    },
    formValid() {
      return this.email_valid && this.password_valid;
      // return true;
    },
  },
  watch: {
    password(newVal) {
      this.password_valid = newVal.length > 0 ? true : false;
    },
    email(newVal) {
      this.email_valid = newVal.length > 0 ? true : false;
    },
  },
  methods: {
    togglePassword() {
      this.pswdVisible = !this.pswdVisible;
    },
    userLogin() {
      const loginPromise = new Promise((resolve) => {
        wsUtils.UserLogin(resolve, {
          email: this.email,
          password: this.password,
        });
      });

      loginPromise.then((data) => {
        console.log("login", data);

        this.loginComplete(data);
      });

      // this.loginComplete({ userID: 0, Status: 3 });
      // this.loginComplete({
      //   userID: "34",
      //   planID: "1",
      //   firstName: "Vivian",
      //   userType: "User",
      //   AccountID: "1",
      //   AccountName: "HotSpex",
      // });
    },
    loginComplete(data) {
      if (data.userID !== 0) {
        window.studioUser = data;
        jsUtils.setCookie("HS-STUDIO", JSON.stringify(data), 1);
      } else {
        if (data.Status === 2) {
          this.msg =
            "Before you can log in, your account needs to be confirmed. Please check your inbox for instructions on how to complete the registration process. If you can't find the email in your Inbox, please check your Junk/SPAM folder or contact support for assistance.";
        } else if (data.Status === 3) {
          this.msg =
            '<p>The email and password you entered did not match our records. Please double-check and try again.</p><p>If you can\'t remember your password, you can use the "Forgot password" function to reset your password.</p>';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  background: url("../../public/images/logBG2.jpg") center no-repeat;
  background-color: #000;
  background-size: cover;
  min-height: 100vh;
  background-attachment: fixed;

  display: grid;
  grid-template-rows: auto 1fr;
}

.topBar {
  background-color: var(--hsred);
  height: 75px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;

  .logoIcon {
    background-color: var(--hsgrey);
    height: 100%;
    padding: 10px;

    img {
      max-height: 100%;
      display: block;
      margin: 0;
    }
  }

  .logoName {
    color: #fff;

    display: flex;
    flex-direction: column;
    padding-left: 10px;
    text-align: left;

    .name1 {
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 2px;
    }

    .name2 {
      font-size: 18px;
      letter-spacing: 3px;
      font-weight: 300;
    }
  }
}

form {
  margin: 2em 0;
}

.failBG {
  margin-bottom: 1em;
  padding: 1em;
}

.field {
  margin-bottom: 2em;

  .lineLable {
    text-transform: uppercase;
    display: block;
    margin-bottom: 8px;
    font-size: 0.85em;
  }

  .iconInput {
    display: grid;
    grid-template-columns: auto 1fr;
    position: relative;
  }

  .icon {
    background-color: var(--iconBGGrey);
    color: #fff;
    height: 32px;
    width: 30px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
  }

  input.fullWidth {
    width: 100%;
    padding: 0 5px;
  }

  .icon.fixRight {
    background-color: transparent;
    color: var(--iconBGGrey);
  }

  .iconBtn {
    cursor: pointer;
  }

  .icon + input {
    transition: background-color 0.5s;
  }

  .icon + input:focus {
    background-color: var(--lightBlue);
  }
}
</style>
